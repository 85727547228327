
import { defineComponent, ref } from 'vue';
import { listPagination, RequestData } from '@/components/common/list';
import firmwareDialog from '@/components/view/installer/community/firmware-dialog';

const headers = [{
    name: 'Version',
    label: WordList.TabelUpdateBoxVersion
}, {
    name: 'Model',
    label: WordList.TabelVersionModel
}, {
    name: 'Log',
    label: WordList.TabelVersionLog,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];
const requestData: RequestData = {
    url: 'v3/web/common/version/getVersionList',
    param: {}
};
export default defineComponent({
    components: {
        listPagination,
        firmwareDialog
    },
    setup() {
        const showDetail = ref(false);
        const initForm = ref({});
        const showFirmWareDetail = (detail: object) => {
            initForm.value = detail;
            showDetail.value = true;
        };
        return {
            headers,
            requestData,
            showDetail,
            initForm,
            showFirmWareDetail
        };
    }
});
